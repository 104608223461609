.auth-container {
  position: relative;
  height: 100vh;
  overflow: hidden;
  font-size: 1.3rem;
  font-weight: 500;
}

.auth-form {
  height: 100vh;
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
}

.auth-form-content {
  width: 290px;
}

.auth-form-content input:focus {
  border: 1px solid var(--color-secondary);
}

.auth-form-image {
  display: none;
}

.auth-header-img {
  width: 200px;
  align-self: center;
  padding: 10px 0;
}

.form__group {
  margin-bottom: 2rem;
}

.title__text {
  text-align: center;
}

.subtitle__text {
  font-size: var(--smaller-font-size);
}


.btn__auth {
  border: none;
  border-radius: var(--border-radius);
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  padding: 0.8rem 2rem;
  text-decoration: none;
  background-color: var(--color-secondary);
  color: var(--color-white);
  text-align: center;
  width: 100%;
  margin: 1rem 0;
}

.forget-password {
  color: var(--color-secondary);
  font-size: var(--normal-font-size);
}

/*===== MEDIA QUERIS =====*/
@media screen and (min-width: 968px){

  .auth-form{
      grid-template-columns: 1.5fr 1fr;
      padding: 0 2rem;
  }
  .auth-form-content{
      width: 320px;
  }
  .auth-form-image{
      display: block;
      width: 700px;
      justify-self: center;
  }
}

.container-auth {
  height: 100vh;
  background: var(--color-white);
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  max-width: 30rem;
}
