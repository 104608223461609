/*=============== GOOGLE FONTS ===============*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
:root {
  --color-primary: #42a5f5;
  --color-primary-alt: #65b4f5;
  --color-secondary: #f69034;
  --color-black: #000;
  --title-color: #22272b;
  --color-white: #fff;
  --color-gray: #404040;
  --bg-primary: #f5f5f5;
  --bg-secondary: #fff;
  --btn-bg-secondary: #ececec;
  --btn-bg-secondary-hover: #3d3d3d;
  --border-radius: 7px;
  --border-radius-big: 10px;
  --border-radius-circle: 50%;
  --border-color: #e5e5e5;
  --box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
  --box-shadow-2: 0 0 0 1px rgba(0, 0, 0, 0.15);

  /*========== Font and typography ==========*/
  /*.5rem = 8px | 1rem = 16px ...*/
  --body-font: "Poppins", sans-serif;
  --big-font-size: 2rem;
  --h1-font-size: 1.4rem;
  --h2-font-size: 1.1rem;
  --h3-font-size: 0.85rem;
  --normal-font-size: 0.850rem;
  --small-font-size: 0.800rem;
  --smaller-font-size: 0.75rem;

  /*========== Margenes Bottom ==========*/
  /*.5rem = 8px | 1rem = 16px ...*/
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
}

/* Responsive typography */
@media screen and (min-width: 968px) {
  :root {
    --big-font-size: 3.5rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}
/*=============== BASE ===============*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--color-white);
  color: var(--color-black);
  transition: 0.3s; /* For animation dark mode */
}

h1,
h2,
h3 {
  color: var(--color-black);
  font-weight: 600;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

input,
button {
  outline: none;
  border: none;
}

form {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
}

form input,
form label {
  width: 100%;
  margin: 0;
  color: var(--color-black);
  transition: .3s;
}

form input {
  padding: 1rem;
  margin-top: 1rem;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
}
/*=============== REUSABLE CSS CLASSES ===============*/
.container {
  max-width: 600px;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.grid {
  display: grid;
  gap: 1.5rem;
}

  /*=============== SCROLL BAR ===============*/
  ::-webkit-scrollbar {
    width: 0.6rem;
    border-radius: 0.5rem;
    background-color: hsl(var(--hue), 8%, 66%);
  }

  ::-webkit-scrollbar-thumb {
    background-color: hsl(var(--hue), 8%, 54%);
    border-radius: 0.5rem;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: hsl(var(--hue), 8%, 44%);
  }

/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 320px) {
  .container {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

/* For large devices */
@media screen and (min-width: 992px) {
  .container {
    margin-left: auto;
    margin-right: auto;
  }
}
