.editor__container {
  max-width: 600px;
}

.editor__body__container {
  max-width: 600px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.editor {
  position: relative;
}

.editor__container {
  row-gap: 2rem;
}

.editor__portada {
  position: relative;
  margin-bottom: 5.5rem;
}

.editor__portada__img {
  width: 100%;
  background: #e7e7e9;
  background-position: center;
  background-size: cover;
  z-index: -1;
}
 
.editor__data {
  display: flex;
  text-align: center;
  position: absolute;
  top: auto;
  left: 6px;
  bottom: -100px;
  right: auto;
  height: auto;
}

.editor__perfil {
  width: 130px;
  height: 130px;
  border-radius: 50%;

  background-color: var(--body-color);
  margin-bottom: 0.5rem;
}

.editor__avatar {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  background-position: center;
  background-size: contain;
  max-width: max-content;
}

.editor__profile__text__container {
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
}

.editor__name {
  font-size: var(--h2-font-size);
  text-align: left;
  border: none;
  width: 100%;
}

.editor__profession {
  font-size: var(--normal-font-size);
  font-weight: 500;
  color: var(--color-gray);
  text-align: left;
  border: none;
}

.editor__company {
  font-size: var(--normal-font-size);
  font-weight: 500;
  color: var(--color-gray);
  text-align: left;
  border: none;
}

.editor__social {
  display: flex;
  justify-content: center;
  column-gap: 0.75rem;
}

.editor__social-link {
  font-size: 1.25rem;
  fill: var(--color-gray);
  transition: 0.3s;
}

.editor__social-link:hover {
  fill: var(--color-primary);
}

.editor__info {
  justify-content: center;
  text-align: center;
}

.editor__info-group {
  text-align: left;
}

.editor__info__title {
  font-size: var(--h3-font-size);
  margin-bottom: 0.25rem;
}

.editor__info-description {
  font-family: inherit;
  font-size: var(--normal-font-size);
  font-weight: 500;
  text-align: center;
  border: none;
  resize: none;
}

.editor__buttons,
.editor__buttons-small {
  display: flex;
  align-items: center;
  justify-content: center;
}

.editor__buttons {
  column-gap: 0.75rem;
}

.editor__buttons-small {
  width: 100%;
  display: grid;
  justify-items: center;
  gap: 1rem;
  grid-template-columns: repeat(4, minmax(1rem, 1fr));
}

.editor__buttons-small svg {
  width: 2.3rem;
  height: 2.3rem;
}

.editor__links__container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.edditor__button__large {
  fill: var(--color-white);
}

/*=============== BUTTONS ===============*/
.editor__button {
  display: inline-flex;
  align-items: center;
  column-gap: 0.5rem;
  background-color: var(--color-primary);
  color: #fff;
  padding: 0.8rem 1.5rem;
  border-radius: 0.5rem;
  transition: 0.3s;
  cursor: pointer;
}

.editor__button box-icon {
  font-size: 1.25rem;
}

.editor__button:hover {
  background-color: var(--color-primary-alt);
}

.editor__button__small {
  padding: 1rem;
  box-shadow: 2px 2px 5px rgb(216, 216, 216);
}

.editor__button__download {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1rem;
  background-color: #f7c65c;
  width: auto !important;
  margin: 0 auto;
  color: var(--title-color);
  box-shadow: 2px 2px 5px rgb(216, 216, 216);
}

.editor__button__download:hover {
  background-color: #f0cd82;
}

.editor__button__gray {
  background-color: var(--btn-bg-secondary);
  fill: var(--title-color);
  box-shadow: 2px 2px 5px rgb(216, 216, 216);
}

.editor__button__gray:hover {
  background-color: var(--text-color-light);
}

.editor__contact__button {
  background-color: var(--color-primary);
  fill: var(--color-white);
  box-shadow: 2px 2px 5px rgb(216, 216, 216);
}

.editor__contact__button box-icon {
  font-size: 1.25rem;
}

.editor__icon__button {
  padding: 0.5rem;
  background-color: rgba(153, 153, 153, 0.288);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editor__custom__links {
  color: var(--color-white);
  background-color: var(--title-color);
  display: flex;
  justify-content: center;
  text-align: center;
  box-shadow: 2px 2px 5px rgb(216, 216, 216);
  cursor: pointer;
}

.editor__custom__links:hover {
  background-color: black;
}

.editor__custom__links svg {
  fill: var(--color-white);
}

.editor__links__container {
  row-gap: 1rem;
}

.tabConatiner {
  width: 100%;
  box-shadow: 0 4px 14px 0 rgb(0 0 0 / 10%);
  border-radius: 12px;
}

.tabButtonsWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12;
}

.tabButton {
  width: 100%;
  padding: 0.8rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: lightgray;
  color: var(--title-color);
  font-size: var(--normal-font-size);
  font-weight: bold;
  transition: 0.3s;
}

.selectedTab {
  background-color: var(--body-color);
}

.tabButton:first-child {
  border-top-left-radius: 12px;
}

.tabButton:nth-child(2) {
  border-top-right-radius: 12px;
}

.tabContent {
  padding: 1rem;
}

.editor__add__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: 500;
  padding: 1.5rem;
  border-radius: 8px;
  background-color: var(--body-color);
  border: 2px dashed var(--color-gray);
  color: var(--color-gray);
  font-size: var(--normal-font-size);
  cursor: pointer;
}

.bottom__sheet__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.editor__form__input {
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  margin-top: 1rem;
  padding: 1rem 0.5rem;
  color: var(--color-black);
  width: 100%;
}

.editor__save__btn {
  margin-top: 1rem;
  font-size: 1rem;
  border: none;
  border-radius: var(--border-radius);
  cursor: pointer;
  display: inline-block;
  padding: 0.8rem 2rem;
  text-decoration: none;
  background-color: var(--color-primary);
  color: var(--color-white);
  text-align: center;
  width: 100%;
}

.banner {
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  width: inherit;
  max-width: inherit;
  padding: 0.4rem 0.5rem;
  font-size: 14px;
  font-weight: 500;
  box-shadow: 2px 2px 5px #999;
  text-decoration: none !important;
  color: black;
}

.editor__tag__container {
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;
  width: 100%;
  max-width: 100%;
  color: black
}

.editor__tag {
  display: flex;
  align-items: center;
  margin: 7px 0;
  margin-right: 10px;
  padding: 0 10px;
  padding-right: 5px;
  border: 1px solid var(--color-primary);
  border-radius: 5px;
  background-color: var(--color-primary);
  white-space: nowrap;
  color: white;
  cursor: pointer;
}

.editor__tag__add {
  border: 1px solid var(--color-gray);
  background-color: var(--color-gray);
}

.editor__tag button {
  display: flex;
  padding: 6px;
  border: none;
  background-color: unset;
  cursor: pointer;
  color: white;
}

.editor__gallery__wrapper {
  flex-direction: row;
  column-gap: 1rem;
  justify-content: center;
}

.editor__gallery__img__wrapper {
  position: relative;
}

.editor__gallery__img {
  width: 100px;
  height: 100px;
  border-radius: 16px;
}
 
.editor__gallery__delete {
  position: absolute;
  top: 0.3rem;
  right: 0.5rem;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  border-radius: 6px;
  background-color: rgba(204, 192, 192, 0.623);
}

.editor__attach__button {
  background-color: var(--body-color);
  color: var(--color-gray);
  fill: var(--color-primary-alt);
  cursor: pointer;
}

.editor__attach__button svg {
  fill: var(--color-primary-alt);
}

.editor__attach__button:hover {
  background-color: var(--body-color);
  color: var(--color-gray);
  fill: var(--color-primary-alt);
}


/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 320px) {
}

/* For medium devices */
@media screen and (min-width: 576px) {
  .editor__container {
    margin-left: auto;
    margin-right: auto;
  }

  .editor__data {
    bottom: -110px;
  }
}

/* For large devices */
@media screen and (min-width: 992px) {
  .editor__container {
    margin-left: auto;
    margin-right: auto;
  }
  .editor__data {
    bottom: -110px;
  }
  .editor__avatar__border {
    width: 135px;
    height: 135px;
    margin-bottom: 1rem;
  }
  .editor__company {
    margin-bottom: 0.5rem;
  }
  .editor__info {
    column-gap: 3rem;
  }
  .editor__info-description {
    font-size: var(--small-font-size);
    text-align: justify;
  }
  .editor__buttons {
    column-gap: 1rem;
  }

  .editor__link {
    width: 332px;
  }
  .editor__form__input {
    width: 20rem;
  }
  .editor__save__btn {
    font-size: 1rem;
    width: 20rem;
  }
}
